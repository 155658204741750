jQuery(document).ready(function($) {
    $(document).on('click', '#sf-load-more', (e) => {
        e.preventDefault();

        let button = $(e.target);
        let page = button.data("page");
        let limit = button.data('limit');
        let items = button.data('items');
        let url = window.location.href;

        // Add specific params to 'load more' url
        if(document.location.href.includes('?')) {
            url += '&sf_data=results&sf_paged='+page;
        }else{
            url += '?sf_data=results&sf_paged='+page;
        }

        $.ajax({
            url: url,
            type: "POST",
            data: {},
            beforeSend: function() {
                button.text("Chargement...");
            },
            success: function(response) {
                if (response) {
                    let configs = getLoadMoreConfigs(items);
                    let new_items = $(response).find(configs.item_wrapper);

                    if (new_items.length > 0) {
                        $(configs.list_container).append(new_items);
                    }

                    if (new_items.length !== limit) {
                        button.text(configs.no_more_text);
                        button.prop("disabled", true);
                    } else {
                        button.data('page', page+1);
                        button.text(configs.see_more_text);
                    }
                }
            },
        });
    });

    // Intercept S&F ajax calls : only after Reset click or select changes.
    $(document).ajaxComplete(function(event, xhr, settings) {
        if (settings.url.includes('sf_data=all')) {
            let loadmore_button = $("#sf-load-more");
            loadmore_button.data('page', 2);

            let configs = getLoadMoreConfigs(loadmore_button.data('items'));
            let new_items = $(configs.list_container).find(configs.item_wrapper);

            if (new_items.length < loadmore_button.data('limit')) {
                loadmore_button.text(configs.no_more_text);
                loadmore_button.prop("disabled", true);
            } else {
                loadmore_button.prop("disabled", false);
                loadmore_button.text(configs.see_more_text);
            }
        }
    });

    function getLoadMoreConfigs(items) {
        // Cocktails (default)
        let item_wrapper = '.listing-single-cocktail-wrapper';
        let list_container = '#listing-cocktails-recipes-container';
        let see_more_text = 'Afficher plus de recettes';
        let no_more_text = 'Plus de recettes disponibles';

        if (items === 'products') {
            item_wrapper = '.listing-product-wrapper';
            list_container = '.listing-products-container';
            see_more_text = 'Afficher plus de produits';
            no_more_text = 'Plus de produits disponibles';
        } else if (items === 'posts') {
            item_wrapper = '.card-single';
            list_container = '.listing-grid';
            see_more_text = 'Afficher plus d\'articles';
            no_more_text = 'Plus d\'articles disponibles';
        }

        return {
            'item_wrapper' : item_wrapper,
            'list_container' : list_container,
            'see_more_text' : see_more_text,
            'no_more_text' : no_more_text,
        };
    }

    // Add dropdown class for all titles
    $('[data-sf-field-input-type="checkbox"] h4').addClass('dropdown-toggle');
    $(document).on('click', '[data-sf-field-input-type="checkbox"] h4', (e) => {
        $(e.target).toggleClass('show');
    });

    // Use native reset behaviour on the custom 'all items' button
    $(document).on('click', '.custom-all-items-button', (e) => {
      e.preventDefault();
      $('.search-filter-reset').trigger('click');
    });

    $(document).on('click', '[data-sf-count="0"]', (e) => {
      e.preventDefault();
    });

    // Sélectionner toutes les checkboxes avec la classe "communique-checkbox"
    const checkboxes = document.querySelectorAll('.communique-checkbox');

    checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', function () {
            // Trouver le lien suivant la checkbox cliquée
            const link = this.nextElementSibling;
            if (link && link.classList.contains('communique-link')) {
                link.click(); // Simuler le clic sur le lien
            }
        });
    });
});
