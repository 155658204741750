import Swiper from 'swiper/bundle';

function initSwiper() {
  new Swiper('.best-products-products-swiper', {
    slidesPerView: "auto",
    spaceBetween: 32,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 3,
        centeredSlides: false,
      },
      1440: {
        slidesPerView: "auto",
        centeredSlides: false,
      },
    },
  });

  new Swiper('.trending-cocktails-swiper', {
    slidesPerView: "auto",
    loop: true,
  });

  new Swiper('.gallery-swiper', {
    slidesPerView: "auto",
    loop: true,
  });
}

initSwiper();
